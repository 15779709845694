<template>
  <fw-panel class="p-5" :title="$t('new_document_translation')">
    <div class="flex flex-col">
      <b-field :label="$t('select_an_option')">
        <b-autocomplete
          v-model="languageSearch"
          :placeholder="$t('search_language_example')"
          :data="filteredLanguage"
          :clearable="true"
          field="name"
          :append-to-body="true"
          :open-on-focus="true"
          @select="option => (language = option.id)"
        >
        </b-autocomplete>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        :type="!allowCreate || loading ? 'disabled' : 'primary'"
        :disable="!allowCreate || loading"
        class="w-32"
        @click.native="create()"
      >
        {{ $t('create') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import ServicePages from '../../services/ServicePages'
import locales from '@/fw-modules/fw-core-vue/utilities/locales.json'
export default {
  name: 'ModalNewLanguage',
  props: {
    usedLanguages: {
      type: Array,
      default: () => [],
    },
    pageKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      languageSearch: '',
      language: null,
      loading: false,
      availableLanguages: locales,
    }
  },
  computed: {
    allowCreate() {
      return this.language != null
    },
    filteredLanguage() {
      return this.availableLanguages.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.languageSearch.toLowerCase()) >= 0 && !this.usedLanguages.includes(option.id)
        )
      })
    },
  },
  methods: {
    async create() {
      //verify language
      if (this.language == null) {
        return
      }
      this.loading = true
      try {
        let result = await ServicePages.createPageVersion(this.pageKey, this.language)
        //console.log('result', result)
        this.$emit('success', result)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      if (!this.loading) {
        this.$emit('close')
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "new_document_translation": "New document translation",
    "select_an_option": "Select a language",
    "search_language_example": "e.g. English - American",
    "cancel": "Cancel",
    "create": "Create"
  },
  "pt": {
    "new_document_translation": "Nova tradução de documento",
    "select_an_option": "Selecione um idioma",
    "search_language_example": "exemplo, English - American",
    "cancel": "Cancelar",
    "create": "Criar"
  }
}
</i18n>
